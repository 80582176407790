import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { breakpointSizes, breakpointQuery, fluidFontSize } from '../utilities';
import Container from './Container';
import FixedImg from './FixedImg';
import { PopupContactButton } from './PopupContact';
import playIcon from '../images/play-icon.svg';
import BannerDots from '../images/banner-dots.svg';

const StyledMediaBanner = styled.section``;

const StyledMediaBannerInner = styled.div`
  padding: 30px 0;

  ${({ hasVideo }) => {
    if (hasVideo) {
      return css`
        text-align: center;
      `;
    } else {
      return css`
        ${breakpointQuery.small`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      `;
    }
  }};

  ${breakpointQuery.large`
    padding-top: 40px;
    padding-bottom: 40px;
  `}

  ${breakpointQuery.xxlarge`
    padding-top: 50px;
    padding-bottom: 50px;
  `}
`;

const StyledMediaBannerContent = styled.div`
  ${({ hasVideo }) => {
    if (!hasVideo) {
      return css`
        ${breakpointQuery.small`
          width: 54%;
        `}

        ${breakpointQuery.medium`
          width: 52%;
        `}

        ${breakpointQuery.large`
          width: 50%;
        `}
      `;
    }
  }};
`;

const StyledMediaBannerHeading = styled.h1`
  ${fluidFontSize(
    '30px',
    '52px',
    breakpointSizes.smedium,
    breakpointSizes.large
  )}
  line-height: 1.3;
`;

const StyledMediaBannerText = styled.div`
  p {
    margin-top: 12px;
    line-height: 1.7;

    ${breakpointQuery.medium`
      margin-top: 16px;
    `}

    ${breakpointQuery.large`
      margin-top: 20px;
    `}

    + p {
      margin-top: 8px;
    }
  }
`;

const StyledMediaBannerPopupContactButton = styled(PopupContactButton)`
  margin-top: 15px;

  ${breakpointQuery.small`
    margin-top: 20px;
  `}

  ${breakpointQuery.large`
    margin-top: 25px;
  `}
`;

const StyledMediaBannerMedia = styled.div`
  display: inline-grid;
  align-items: center;
  margin-top: 30px;

  ${({ hasVideo }) => {
    if (hasVideo) {
      return css`
        justify-content: center;
        max-width: 95%;

        ${breakpointQuery.tiny`
          margin-top: 35px;
          max-width: 85%;
        `}

        ${breakpointQuery.small`
          margin-top: 40px;
          max-width: 75%;
        `}
      `;
    } else {
      return css`
        width: 100%;

        ${breakpointQuery.small`
          margin-top: 0;
          width: 40%;
        `}
      `;
    }
  }};
`;

const StyledSvgWrap = styled.div`
  grid-area: 1/1/1/1;
  text-align: center;
`;

const StyledMediaBannerImage = styled.img`
  margin: auto;
  max-height: 220px;

  ${breakpointQuery.tiny`
    max-height: 300px;
  `}

  ${breakpointQuery.small`
    max-height: 380px;
  `}
`;

const StyledImageWrap = styled.div`
  grid-area: 1/1/1/1;
  width: 100%;
  max-width: 370px;
  margin: auto;
`;

const StyledMediaBannerVideoWrapper = styled.div`
  position: relative;
  grid-area: 1/1/1/1;
`;

const StyledMediaBannerVideo = styled.video`
  display: block;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 12px 0;
`;

const StyledMediaBannerVideoPlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  height: 12vw;
  width: 12vw;
  max-height: 120px;
  max-width: 120px;
  background: none;
  border: none;
  cursor: pointer;
  transform: translate(-50%, -50%);

  img {
    height: 100%;
    width: 100%;
  }
`;

const StyledMediaBannerDots = styled.img`
  padding: 0 10px;
  width: 100%;
  max-height: 140px;
`;

const MediaBanner = ({
  heading,
  text,
  image,
  video,
  buttonText,
  isCampaignPage,
}) => {
  const videoEl = useRef(null);
  const [videoPlayed, setVideoPlayed] = useState(false);
  return (
    <StyledMediaBanner>
      <Container narrow={video}>
        <StyledMediaBannerInner hasVideo={video}>
          <StyledMediaBannerContent hasVideo={video}>
            <StyledMediaBannerHeading>{heading}</StyledMediaBannerHeading>
            <StyledMediaBannerText dangerouslySetInnerHTML={{ __html: text }} />
            {isCampaignPage && (
              <StyledMediaBannerPopupContactButton buttonText={buttonText} />
            )}
          </StyledMediaBannerContent>
          <StyledMediaBannerMedia hasVideo={video}>
            {image &&
              (image.format === 'svg' ? (
                <StyledSvgWrap>
                  <StyledMediaBannerImage
                    src={image.url}
                    loading="lazy"
                    alt={image.alt}
                  />
                </StyledSvgWrap>
              ) : (
                <StyledImageWrap>
                  <FixedImg image={image} />
                </StyledImageWrap>
              ))}
            {video && (
              <StyledMediaBannerVideoWrapper>
                <StyledMediaBannerVideo controls ref={videoEl}>
                  <source src={video.video.mp4Url} type="video/mp4" />
                </StyledMediaBannerVideo>
                {!videoPlayed && (
                  <StyledMediaBannerVideoPlayButton
                    aria-label="Play video"
                    onClick={() => {
                      videoEl.current.play();
                      setVideoPlayed(true);
                    }}
                  >
                    <img src={playIcon} alt="Play icon" loading="lazy" />
                  </StyledMediaBannerVideoPlayButton>
                )}
              </StyledMediaBannerVideoWrapper>
            )}
          </StyledMediaBannerMedia>
        </StyledMediaBannerInner>
      </Container>
      <StyledMediaBannerDots src={BannerDots} loading="lazy" alt="Dots" />
    </StyledMediaBanner>
  );
};

export default MediaBanner;
