import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import MediaBanner from '../components/MediaBanner';
import Logos from '../components/Logos';
import ModularBlocks from '../components/ModularBlocks';

const HomePage = ({
  data: {
    datoCmsHome: {
      seoMetaTags,
      bannerHeading,
      bannerText,
      bannerIllustration,
      clientLogos,
      modularBlocks,
    },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <MediaBanner
        heading={bannerHeading}
        text={bannerText}
        image={bannerIllustration}
      />
      <Logos heading="Clients" items={clientLogos} />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const HomePageQuery = graphql`
  query HomePageQuery {
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      bannerHeading
      bannerText
      bannerIllustration {
        url
        alt
        format
      }
      clientLogos {
        url
        alt
      }
      modularBlocks {
        ...CaseStudiesModularBlockFragment
        ...ContactCallToActionModularBlockFragment
        ...ContentModularBlockFragment
        ...DemoCallToActionModularBlockFragment
        ...DemoFormModularBlockFragment
        ...DotsDividerModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...IconCallToActionsModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageModularBlockFragment
        ...MoleculesDividerModularBlockFragment
        ...NewsInsightsModularBlockFragment
        ...ProductsServicesModularBlockFragment
        ...StatisticsModularBlockFragment
        ...TeamProfilesModularBlockFragment
        ...TestimonialModularBlockFragment
        ...TestimonialsSliderModularBlockFragment
        ...VideoContentModularBlockFragment
        ...VideoModularBlockFragment
        ...VideosModularBlockFragment
      }
    }
  }
`;

export default HomePage;
