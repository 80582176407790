import React from 'react';
import styled from 'styled-components';
import { breakpointQuery, standardColours, visuallyHidden } from '../utilities';
import Animation from './Animation';
import Container from './Container';

const StyledLogos = styled.section`
  position: relative;
  margin-top: -10px;

  ${breakpointQuery.small`
    margin-top: -15px;
  `}

  ${breakpointQuery.medium`
    margin-top: -20px;
  `}

  ${breakpointQuery.large`
    margin-top: -25px;
  `}

  ${breakpointQuery.xxlarge`
    margin-top: -30px;
  `}
`;

const StyledLogosInner = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
  background-color: ${standardColours.white};
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px 0;
`;

const StyledLogosHeading = styled.h2`
  ${visuallyHidden()}
`;

const StyledLogosImage = styled.img`
  margin: 10px;
  height: 40px;
  max-width: 160px;
`;

const Logos = ({ heading, items }) => (
  <StyledLogos>
    <Animation>
      <Container>
        <StyledLogosInner>
          <StyledLogosHeading>{heading}</StyledLogosHeading>
          {items.map(({ url, alt }, i) => (
            <StyledLogosImage
              key={`logo-${i}`}
              src={url}
              loading="lazy"
              alt={alt}
            />
          ))}
        </StyledLogosInner>
      </Container>
    </Animation>
  </StyledLogos>
);

export default Logos;
